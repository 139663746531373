.Card {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.CardHeader {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.CardTitle h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.CardTitle h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.VideoPreview {
  /* margin: 0.5em auto;
  width: 80%;
  max-width: 20em; */
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.Description {
  margin: 0.5rem 0;
  color: #898786;
  font-size: 0.9rem;
}

.DescriptionPlus {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #898786;
  font-size: 0.9rem;
  margin-top: 1.5rem;
}
.DescriptionPlus span {
  color: #3d9dc7;
  font-weight: 900;
}

.CardContent {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.Emoji {
  font-size: 1.8rem;
}

.QuestionsHeader {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.QuestionTitle {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.QuestionTitle p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.CommentSection {
  margin-top: 2rem;
  text-align: center;
}

.Buttons {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.NCSection {
  margin-top: 1.1em;
}
.MapImage {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.Notices {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .Card {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    max-width: 50rem;
    outline: none;
    display: flex;
  }
  .Card::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .Card {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .VideoPreview {
    margin-top: 3rem;
  }

  .LeftSection {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .RightSection {
    background-color: #f3f2f0;
    width: 50%;
    position: relative;
  }

  .DescriptionPlus {
    margin-top: 0;
  }

  .CardContent {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
