@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Sans", sans-serif;
  background: #f3f2f0;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(/static/media/Futura_Extra_Bold.db7c22c7.otf) format("truetype");
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

/* .App {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Login_Wrapper__3grNv {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.Login_LoginContainer__pliJ1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login_Login__ncr-h {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.Login_ImgWrapper__3alkD {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.Login_ImgWrapper__3alkD img {
  width: 100%;
}

.Login_LoginTitle__3kjn2 {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.Login_LoginTitle__3kjn2 span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.Login_InputGroup__2dncg {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Login_Input__2OHLl {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Login_Input__2OHLl:focus {
  outline: none;
}
.Login_Phone__37xc8 {
  padding-left: 45px;
}
.Login_InputGroup__2dncg i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.Login_MUIInputGroup__NzgDN {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.Login_InputError__LJMke {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.Login_InputLabel__1dmZH {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Login_Input__2OHLl:focus ~ .Login_InputLabel__1dmZH,
.Login_Input__2OHLl:valid ~ .Login_InputLabel__1dmZH {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Login_Bar__2e0fu {
  position: relative;
  display: block;
  width: 100%;
}
.Login_Bar__2e0fu:before,
.Login_Bar__2e0fu:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Login_Bar__2e0fu:before {
  left: 50%;
}
.Login_Bar__2e0fu:after {
  right: 50%;
}
/* active state */
.Login_Input__2OHLl:focus ~ .Login_Bar__2e0fu:before,
.Login_Input__2OHLl:focus ~ .Login_Bar__2e0fu:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Login_Highlight__3nbqD {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Login_Input__2OHLl:focus ~ .Login_Highlight__3nbqD {
  animation: Login_inputHighlighter__29BbC 0.3s ease;
  -webkit-animation: Login_inputHighlighter__29BbC 0.3s ease;
  -moz-animation: Login_inputHighlighter__29BbC 0.3s ease;
}
/* Animations */
@keyframes Login_inputHighlighter__29BbC {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes Login_inputHighlighter__29BbC {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Login_Indicatif__1ymqa {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Login_Input__2OHLl:focus ~ .Login_Indicatif__1ymqa,
.Login_Input__2OHLl:valid ~ .Login_Indicatif__1ymqa {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */

.Login_CGUGroup__3bF7P input[type="checkbox"] {
  display: none;
}
.Login_CGUGroup__3bF7P input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.Login_CGUGroup__3bF7P input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.Login_CGUGroup__3bF7P input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.Login_CGUGroup__3bF7P input[type="checkbox"] + label:before {
  content: "\f096";
}
.Login_CGUGroup__3bF7P input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.Login_CGUGroup__3bF7P label {
  color: #828489;
  font-size: 0.9em;
}
.Login_CGUGroup__3bF7P span {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.Login_SubmitButtonContainer__2a2nq {
  margin-top: 40px;
}

/* Anon link */
.Login_AnonLogin__10q_y {
  margin-top: 1em;
  text-align: center;
}

.RefInfo_RefInfoTitle__1zPcF {
  display: flex;
}
.RefInfo_RefInfoTitle__1zPcF h3 {
  margin-left: 8px;
}

.RefInfo_RefWrapper__2w062 img {
  width: 100%;
  margin-top: 15px;
}

.CGU_CGUTitle__2mDuS {
  text-align: center;
}

.CGU_CGUWrapper__1b5Zi {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.CGU_Article__2Fp4S {
  margin-bottom: 1em;
}

.CGU_ArticleTitle__2Jbf6 {
  margin-bottom: 0.5em;
}
.CGU_ArticleSubtitle__1Vzqh {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__24RnA {
  margin-bottom: 0.5em;
}
.CGU_ArticleContent__24RnA p {
  margin-bottom: 0.3em;
}

.Modal_Modal__2BGX5 {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal_Modal__2BGX5 {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.Backdrop_Backdrop__22OoN {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  /* background-color: rgba(229, 229, 229, 0.8); */
  background-color: rgba(0, 0, 0, 0.5);
}

.SubmitButton_SubmitButton__2VxDD {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton_SubmitButton__2VxDD:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton_SubmitButton__2VxDD:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.SubmitButton_Invalid__Zh2wO i {
  -webkit-transform: translate(-30vw, 11vh);
          transform: translate(-30vw, 11vh);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 0;
}
.SubmitButton_Invalid__Zh2wO span {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.SubmitButton_Valid__2sdkn i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.SubmitButton_Clicked__Ew7aI i {
  -webkit-transform: translate(100vw, -90vh);
          transform: translate(100vw, -90vh);
  -webkit-transition: all linear 1.3s;
  transition: all linear 1.3s;
}

.CircularLoading_CircularLoading__2rThP {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Home_SubNavbar__GisL- {
  margin: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}
.Home_SubNavbar__GisL- p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.Home_ActionButton__3qUFO p {
  color: white;
  background: #8bc63e;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  position: relative;
}
.Home_ActionButtonSvg__2Z3I2 {
  display: none;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Home_SubNavbar__GisL- {
    margin: 0 4rem;
  }
  .Home_ActionButtonSvg__2Z3I2 {
    display: block;
    width: 15rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    top: -35rem;
    right: 10rem;
    right: 3rem;
  }
  .Home_Footer__1hls4 {
    width: 100%;
    margin-top: -5rem;
    padding-right: 5rem;
    display: flex;
    justify-content: center;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Home_SubNavbar__GisL- {
    margin: 0 11rem;
  }
  .Home_ActionButtonSvg__2Z3I2 {
    right: 10rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.HomeToolbar_Header__Ka23P {
  background-color: #f3f2f0;
  padding: 2rem 1.5rem;
}

.HomeToolbar_Toolbar__29xlT {
  display: flex;
  align-items: left;
  flex-direction: column;
}
.HomeToolbar_Logo__2BYI1 {
  width: 90vw;
  margin-top: 1rem;
}

.HomeToolbar_Title__1fe85 {
  margin-top: 1.5rem;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.HomeToolbar_Title__1fe85 h3 {
  font-size: 1.8rem;
  color: #292f33;
  text-transform: uppercase;
  font-weight: 900;
}
.HomeToolbar_Title__1fe85 h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #5b5855;
}

.HomeToolbar_Description__3Yd9O {
  color: #5b5855;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .HomeToolbar_Header__Ka23P {
    padding: 2rem 4rem;
  }
  .HomeToolbar_Toolbar__29xlT {
    align-items: center;
    flex-direction: row;
  }
  .HomeToolbar_Logo__2BYI1 {
    width: 30vw;
    margin: 0;
  }
  .HomeToolbar_Title__1fe85 h3 {
    font-size: 1.8rem;
  }
  .HomeToolbar_Title__1fe85 {
    display: flex;
    flex-direction: column;
  }
  .HomeToolbar_Title__1fe85 h4 {
    margin-left: 0;
  }
  .HomeToolbar_Description__3Yd9O {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .HomeToolbar_Header__Ka23P {
    padding: 2rem 11rem;
  }
  .HomeToolbar_Logo__2BYI1 {
    margin-left: 2rem;
  }

  .HomeToolbar_Title__1fe85 h3 {
    font-size: 2rem;
  }
}

.HomeFooter_Footer__1DFrl {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomeFooter_LogoContainer__2bJjX {
  width: 15em;
  margin: 1em 0;
}
.HomeFooter_LogoContainer__2bJjX img {
  width: 100%;
}
.HomeFooter_LegalLinks__3FBec {
  display: flex;
  margin-top: 1em;
  margin-bottom: 0rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.HomeFooter_LegalLinks__3FBec p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.HomeFooter_Copyrights__2p30z {
  margin: 1em 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.HomeFooter_Copyrights__2p30z span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}

.HomeFooter_ImmobiliereLogo__2Lh-x {
  width: 13rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.HomeFooter_Frise__2HZMq {
  width: 100%;
}

.PDC_CGUTitle__2TP77 {
  text-align: center;
}

.PDC_CGUWrapper__2GhAL {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.PDC_Article__jSCOy {
  margin-bottom: 1em;
}

.PDC_ArticleTitle__2gikI {
  margin-bottom: 0.5em;
}
.PDC_ArticleSubtitle__14F6z {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2j2bm {
  margin-bottom: 0.5em;
}
.PDC_ArticleContent__2j2bm p {
  margin-bottom: 0.3em;
}

.ViewSelector_ViewSelector__2tv4y {
  display: flex;
  justify-content: space-between;
}
.ViewSelector_ViewSelector__2tv4y p {
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
}
.ViewSelector_UnselectedView__zvP0Q {
  color: #292f33;
  background: #f3f2f0;
  border: 1px solid #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}
.ViewSelector_SelectedView__2Lebb {
  background: #3b4578;
  color: white;
  border: 1px solid #3b4578;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ViewSelector_ViewSelector__2tv4y {
    width: 15rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarDesktop_Calendar__wXR9s {
  margin: 4rem 4rem;
}

.CalendarDesktop_CalendarTitle__1jmBZ {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarDesktop_CalendarTitle__1jmBZ span {
  font-weight: 700;
}

.CalendarDesktop_Main__1CXok {
  display: flex;
  margin-top: 2rem;
}

.CalendarDesktop_CardsList__AWOvm {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.CalendarDesktop_Modal__US650 {
  min-width: 21rem;
  max-width: 21rem;
}
.CalendarDesktop_PlanSchematique__3M3lQ {
  /* background-color: white; */
  /* border-radius: 15px; */
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarDesktop_Calendar__wXR9s {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarCard_Card__16XME {
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_Emoji__1u55R {
  font-size: 2rem;
  margin: auto;
}
.CalendarCard_Card__16XME p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}
.CalendarCard_SelectedCard__2yaZ1 {
  background: #3b4578;
  border-radius: 18px;
  color: white;
  max-width: 15rem;
  min-width: 15rem;
  max-height: 10rem;
  padding: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.CalendarCard_SelectedCard__2yaZ1 p {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarModal_CalendarModal__2MRDx {
  background: #ffffff;
  border-radius: 18px;
  height: 30rem;
  overflow-y: scroll;
  /*-ms-overflow-style: none;  IE and Edge */
  /*scrollbar-width: none;  Firefox */
}
/* .CalendarModal::-webkit-scrollbar {
  display: none;
} */

.CalendarModal_StepTitle__g4fk7 {
  background-color: #3b4578;
  color: white;
  border-radius: 18px 18px 0 0;
  display: flex;
  padding: 1rem;
  font-size: 1.18rem;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
}
.CalendarModal_TitleEmoji__1LuoV {
  font-size: 3rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}
.CalendarModal_Substeps__33y2y {
  padding: 1rem 1rem;
}
.CalendarModal_Substep__2l1uZ {
  color: #292f33;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.CalendarModal_Description__2hGHk {
  display: flex;
  margin-bottom: 0.3rem;
}
.CalendarModal_Emoji__1-g3A {
  font-size: 2rem;
  margin-right: auto;
  margin-left: auto;
}

.CalendarModal_Plan__2y2oK {
  margin-bottom: 2rem;
  text-align: center;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.mapButton_Modal__FiHf5 {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  width: 90%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.mapButton_Modal__FiHf5::-webkit-scrollbar {
  display: none;
}

.mapButton_ModalContent__1l_Ub {
  width: 90%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .mapButton_ModalContent__1l_Ub {
    width: 50%;
  }
  .mapButton_Modal__FiHf5 {
    width: 50%;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .mapButton_ModalContent__1l_Ub {
    width: 40%;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.CalendarMobile_Calendar__244Fe {
  margin: 4rem 4rem;
}

.CalendarMobile_CalendarTitle__2KnWr {
  font-size: 1.25rem;
  color: #292f33;
}
.CalendarMobile_CalendarTitle__2KnWr span {
  font-weight: 700;
}

.CalendarMobile_Main__3WuXe {
  display: flex;
  margin-top: 2rem;
}

.CalendarMobile_CardsList__GIIak {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.CalendarMobile_Modal__3gAar {
  width: 80%;
  outline: none;
}
.CalendarMobile_PlanSchematique__3HPuw {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .CalendarMobile_Calendar__244Fe {
    margin: 4rem 11rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Projet_Projet__2P6yR {
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
}
.Projet_Projet__2P6yR p {
  margin-bottom: 1.5rem;
  color: #5b5855;
}
.Projet_Articles__16ODk {
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
.Projet_ArticlesHeader__2Xb1p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Projet_Description__23DPX p {
  text-align: justify;
}

.Projet_ActionButton__1L5zW {
  display: none;
}
.Projet_ActionButton__1L5zW p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  background: #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: 4rem;
}
.Projet_ActionButtonSvg__2OMRC {
  display: none;
}

.Projet_Description__23DPX ul {
  list-style: none;
  margin-left: 2rem;
  color: #5b5855;
}

.Projet_Description__23DPX ul li::before {
  content: "\2022";
  color: #8bc63a;
  font-weight: bold;
  display: inline-block;
  width: 1.5em;
  margin-left: -1em;
}
.Projet_DescriptionSvg__1S1cC {
  max-width: 20rem;
  margin: 0 auto;
}
.Projet_LetterTitle__1kE7o {
}
.Projet_LetterPhoto__3W4o3 {
  width: 40%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Projet_Projet__2P6yR {
    display: flex;
    flex-flow: row;
    padding: 4rem 4rem;
  }
  .Projet_Articles__16ODk {
    margin-top: 0;
  }
  .Projet_Description__23DPX {
    margin-right: 5rem;
  }
  .Projet_DescriptionSvg__1S1cC {
    display: none;
  }
  .Projet_DescriptionSection__QSWhP {
    position: relative;
  }
  .Projet_Description__23DPX {
    /* margin-left: 13vw; */
    margin-right: 5vw;
  }

  .Projet_ActionButton__1L5zW {
    display: none;
  }

  .Projet_BackgroundShape__1pr9f {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
  .Projet_LetterTitle__1kE7o {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .Projet_LetterPhoto__3W4o3 {
    width: 10rem;
    margin: 0;
    margin-left: 1rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Projet_Projet__2P6yR {
    padding: 4rem 11rem;
    padding-right: 5rem;
  }

  .Projet_DescriptionSection__QSWhP {
    position: relative;
  }
  .Projet_Description__23DPX {
    margin-left: 13vw;
    margin-right: 5vw;
  }
  .Projet_DescriptionSvg__1S1cC {
    display: block;
    width: 25vw;
    position: absolute;
    left: -11rem;
    top: -3rem;
    z-index: -1;
  }

  .Projet_ActionButton__1L5zW {
    display: block;
    position: relative;
  }

  .Projet_ActionButtonSvg__2OMRC {
    display: block;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
  .Projet_ActionButtonSvg__2OMRC::before {
    content: "";
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .Projet_BackgroundShape__1pr9f {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ArticleModal_ArticleModal__3FjQT {
  padding: 0;
  border-radius: 10px;

  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ArticleModal_ArticleModal__3FjQT::-webkit-scrollbar {
  display: none;
}

.ArticleModal_ModalContent__qERAa {
  width: 80%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ArticleModal_ModalContent__qERAa {
    width: 50%;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .ArticleModal_ModalContent__qERAa {
    width: 40%;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.Articles_ArticleCard__34G16 {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.Articles_ArticleCard__34G16 p {
  margin: 0;
  color: #292f33;
  font-size: 1rem;
}
.Articles_Emoji__1A9OD {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}
.Articles_DeleteButton__1ozyb {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Articles_ArticleCard__34G16 {
    min-width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Articles_ArticleCard__34G16 {
    min-width: 20rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.UploadDocument_AddButton__26ubj p {
  background: #ee9834;
  color: white;
  border: 1px solid #ee9834;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.UploadDocument_ModalContent__13ZAK {
  background-color: #f3f2f0;
  padding: 2rem;
  border-radius: 10px;
  width: 30rem;
  outline: none;
}
.UploadDocument_QuestionTitle__3sXOo {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.UploadDocument_QuestionTitle__3sXOo p {
  color: #2a2f39;
  margin-left: 0.4em;
}
.UploadDocument_TitleField__3igpg {
  margin-top: 1rem;
}
.UploadDocument_CloudIcon__ckGX4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.6rem;
  justify-content: center;
}
.UploadDocument_Buttons__1M_hS {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .UploadDocument_ModalContent__13ZAK {
    width: 80%;
    outline: none;
  }
}

.ProfileSetup_Wrapper__20HpR {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetup_ProfileSetupContainer__39W0X {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup_ProfileSetup__12yak {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ProfileSetup_ImgWrapper__1sDyr {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ProfileSetup_ImgWrapper__1sDyr img {
  width: 100%;
}

.ProfileSetup_Title__diWVI {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.ProfileSetup_Title__diWVI span {
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    color-stop(60%, rgba(255, 255, 255, 0)),
    color-stop(40%, rgba(0, 78, 124, 0.178))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.ProfileSetup_CircularProgress__2ZyEZ {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileSetup_QuestionContainer__yzwFU {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ProfileSetup_ButtonsContainer__2FqBy {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.ProfileSetup_Button__1ZQYD {
  display: inline-block;
  margin: 0 1rem;
}
.ProfileSetup_SubmitButton__1VBDA {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.NextButton_NextButton__14Y6z {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.NextButton_NextButton__14Y6z:hover {
  background-color: #3d9dc7;
  color: white;
}

.NextButton_NextButton__14Y6z:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.NextButton_NextButton__14Y6z i {
  padding-left: 2px;
}

.BackButton_BackButton__39rb0 {
  line-height: 0;
  width: 58px;
  height: 58px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: #777;
  background: #3d9ec73f;
  border: none;
  border-radius: 50px;
}

.BackButton_BackButton__39rb0:hover {
  background-color: #3d9dc7;
  color: white;
}

.BackButton_BackButton__39rb0:active {
  background-color: #3d9dc7;
  color: white;
  box-shadow: inset 1px 1px 2px #80012d, inset -1px -1px 2px #d6929850;
}
.BackButton_BackButton__39rb0 i {
  padding-right: 2px;
}

.CircularProgressBar_Circle__3h3NH {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid #c9d100;
  box-sizing: border-box;
  border-radius: 50%;
  /* animation-name: progress;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Circle__3h3NH::after {
  content: "";
  position: absolute;
  top: -21px;
  left: -21px;
  width: 180px;
  height: 180px;
  border: 22px solid #fff;
  box-sizing: border-box;
  border-radius: 50%;
  -webkit-clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
          clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0);
  /* animation-name: progress_after;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.CircularProgressBar_Etape__tLCaK {
  margin-top: 0.35em;
  font-weight: bold;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.QuestionOne_QuestionOne__yc9Bo {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionOne_QuestionOne__yc9Bo p {
  margin: 20px;
  font-size: 1.1rem;
}

.QuestionTwo_QuestionTwo__AbaAG {
  margin: 1.3em 0;
  text-align: center;
}
.QuestionTwo_QuestionTwo__AbaAG p {
  margin: 20px;
  font-size: 1.1rem;
}

.Radio_FormTitle__2a5sG {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Radio_Form__3MlvN {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Radio_FormElement__gMuAQ {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.Radio_FormElement__gMuAQ input[type="radio"] {
  display: none;
}

.Radio_FormElement__gMuAQ label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: #3d9ec756;
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Radio_FormElement__gMuAQ input[type="radio"]:checked + label {
  background-color: #3d9dc7;
  color: white;
}

/* .Travaux {
  overflow-x: hidden;
} */
.Travaux_SubHeader__3ZvVg {
  background-color: #8bc63e;
  padding: 0;
  margin-top: -0.1rem;
  padding-top: 2rem;
}
.Travaux_HomeLink__2I448 {
  color: white;
  display: flex;
  justify-content: center;
}

.Travaux_HomeLink__2I448 a {
  color: white;
  font-size: 0.9rem;
  margin-left: 0.4rem;
  text-decoration: underline;
}
.Travaux_Main__2DMP4 {
  padding: 0 0.2em;
  margin-bottom: 5em;
}
.Travaux_CardListTitle__1k2kc {
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Travaux_CardListTitle__1k2kc p {
  font-size: 1.2rem;
  text-align: center;
  color: #828489;
  margin-bottom: 1rem;
}
.Travaux_CardListTitle__1k2kc span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #292f33;
}
.Travaux_Travaux__3EIcf {
  /* margin-bottom: 4em; */
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Travaux_SubHeader__3ZvVg {
    padding: 0 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Travaux_CardListTitle__1k2kc {
    flex-direction: row;
    justify-content: space-between;
  }
  .Travaux_CardListTitle__1k2kc p {
    font-size: 1.4rem;
  }
  .Travaux_CardListTitle__1k2kc span {
    font-size: 1.4rem;
  }
  .Travaux_Travaux__3EIcf {
    margin: 0 4rem;
    margin-top: 1em;
    /* margin-bottom: 4em; */
  }
  .Travaux_MainCards__2GfoR {
    display: flex;
    position: relative;
    min-height: 20em;
  }
  /* .NoCard {
    background-color: #f3f2f08a;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
    position: absolute;
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 20em;
  } */
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Travaux_SubHeader__3ZvVg {
    padding: 0 11rem;
  }
  .Travaux_Travaux__3EIcf {
    margin: 0 11em;
    /* margin-bottom: 4em; */
  }
}

.IntroModal_IntroModal__373Fh {
  height: 50vh;
  overflow-y: scroll;
  margin-bottom: 3em;
}

.IntroModal_IntroTitle__4WMkX {
  /* margin-right: 1em; */
}

.IntroModal_IntroDescription__CDWL3 {
  margin-right: 1em;
}

.IntroModal_IntroButton__2H4K1 {
  margin-top: 1.5em;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
  position: fixed;
  bottom: 0.2em;
  width: 90%;
}

.IntroModal_Personnalisable__WBy2P {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #bbd5ff));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #bbd5ff 40%);
}

.IntroModal_Informative__aK_D- {
  cursor: pointer;
  /* font-weight: 700; */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, rgba(255, 255, 255, 0)), color-stop(40%, #fcd79d));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #fcd79d 40%);
}

.Toolbar_Header__OWByT {
  background-color: #3b4578;
  padding: 2rem 1.5rem;
}

.Toolbar_Toolbar__wt4Lf {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Toolbar_Title__1QR3L {
  margin-top: 1.5rem;
  color: white;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.Toolbar_Title__1QR3L h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 900;
}
.Toolbar_Title__1QR3L h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

.Toolbar_Description__1PlHf {
  color: white;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Toolbar_Header__OWByT {
    padding: 4rem 4rem;
  }
  .Toolbar_Title__1QR3L h3 {
    font-size: 1.8rem;
  }
  .Toolbar_Title__1QR3L {
    display: flex;
  }
  .Toolbar_Title__1QR3L h4 {
    margin-left: 1rem;
  }
  .Toolbar_Description__1PlHf {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar_Header__OWByT {
    padding: 2rem 11rem;
  }

  .Toolbar_Title__1QR3L h3 {
    font-size: 2rem;
  }
}

.Filter_Filter__150_y {
}
.Filter_Filter__150_y p {
  font-size: 0.9rem;
}
.Filter_Filter__150_y span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Filter_Categories__3rb_P form {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1rem 0;
  /* flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; 
  scrollbar-width: none;  */
}
/* .Categories form::-webkit-scrollbar {
  display: none; 
} */

.Filter_Personnalisable__2atNA {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Personnalisable__2atNA label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Personnalisable__2atNA input[type="checkbox"] {
  display: none;
}

.Filter_Personnalisable__2atNA input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* =========================== */
.Filter_Informative__SEwHZ {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Filter_Informative__SEwHZ label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Filter_Informative__SEwHZ input[type="checkbox"] {
  display: none;
}

.Filter_Informative__SEwHZ input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* .CardList {
  margin-bottom: 2em;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* large tablets & laptops */
/* @media screen and (min-width: 768px) {
  .List {
    align-items: flex-start;
    width: 21em;
  }
} */

.CardList_CardList__13D8U {
  margin-bottom: 2em;
}

.CardList_List__1gkNJ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  /* .List {
    align-items: flex-start;
    width: 21em;
  } */
}

.Card_Card__2NrvL {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.Card_SelectedCard__2SoIV {
  border: 2px solid #e57373;
}

.Card_Thumbnail__CF4jG {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.Card_Thumbnail__CF4jG img {
  border-radius: 14px;
  width: 100%;
}

.Card_Main__2WsSq {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Card_Titre__1cPZl {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.Card_Titre__1cPZl span {
  font-weight: 700;
}

.Card_Description__Wd2wf {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Card_Icon__dkcCB {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.Card_TagContainer__5cNaa {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.Card_Tag__1wKFR {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.Card_Tag__1wKFR p {
  padding: 0 0.2rem;
}

.CardCreateReclamation_Card__JSZf- {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;

  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.CardCreateReclamation_SelectedCard__3steS {
  border: 2px solid #e57373;
}

.CardCreateReclamation_Thumbnail__9WmUI {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardCreateReclamation_Thumbnail__9WmUI img {
  border-radius: 14px;
  width: 100%;
}

.CardCreateReclamation_Main__1JHq7 {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardCreateReclamation_Titre__3Slv6 {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardCreateReclamation_Titre__3Slv6 span {
  font-weight: 700;
}

.CardCreateReclamation_Description__1uGMk {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardCreateReclamation_Icon__mf4dB {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardCreateReclamation_TagContainer__hPqUg {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardCreateReclamation_Tag__FqwWN {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardCreateReclamation_Tag__FqwWN p {
  padding: 0 0.2rem;
}

.ModalCreateReclamation_ModalCreateReclamation__TjRIW {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalCreateReclamation_ModalCreateReclamation__TjRIW::-webkit-scrollbar {
  display: none;
}

.ModalCreateReclamation_ModalContent__1nM7y {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalCreateReclamation_ModalTitle__nU8av {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalCreateReclamation_ModalContent__1nM7y {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: auto;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__2eGnb {
  margin: auto;
  width: 50%;
}

.ReclamationContent_Buttons__1EAtE {
  /* position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__2opvw {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__2opvw p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__1iQx0 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__3hfpN {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__3hfpN input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__URZLY {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__39jj7 {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__2JUc3 {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__-V9ye {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__3n4p- {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__36Zoh {
  display: flex;
}
.ReclamationContent_RecapTags__36Zoh p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__B_8yK {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__30kQU {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__30kQU::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__3tJR0 {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2Nqzr {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2Nqzr::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__3GbaI {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__3hfpN {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.CardUserReclamation_Card__nY6wl {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  border: 3px solid #3b4578;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}
.CardUserReclamation_SelectedCard__3hyKQ {
  border: 2px solid #e57373;
}

.CardUserReclamation_Thumbnail__9Rb-v {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  align-self: center;
}
.CardUserReclamation_Thumbnail__9Rb-v img {
  border-radius: 14px;
  width: 100%;
}

.CardUserReclamation_Main__3Aisn {
  margin: 0.5em 0;
  min-width: 11rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CardUserReclamation_Titre__30Nik {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.CardUserReclamation_Titre__30Nik span {
  font-weight: 700;
}

.CardUserReclamation_Description__aD_Y5 {
  margin-right: 4em;
  font-size: 0.6rem;
}

.CardUserReclamation_Icon__2GVS2 {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.CardUserReclamation_TagContainer__1WArT {
  font-size: 0.6rem;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CardUserReclamation_Tag__1V_MH {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.CardUserReclamation_Tag__1V_MH p {
  padding: 0 0.2rem;
}

.CardUserReclamation_Notification__1pC3t {
  position: absolute;
  top: -1.2rem;
  left: 1rem;
  padding: 0.3rem;
  border-radius: 100px;
  background-color: #fcb34a;
}

.ModalUserReclamation_ModalCreateReclamation__3wT-g {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalUserReclamation_ModalCreateReclamation__3wT-g::-webkit-scrollbar {
  display: none;
}

.ModalUserReclamation_ModalContent__m8yIh {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalUserReclamation_ModalTitle__w3L1m {
  margin-top: 1.4rem;
  margin-bottom: 1.7rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b4578;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalUserReclamation_ModalContent__m8yIh {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: auto;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.ReclamationContent_IntroImage__4rZIz {
  margin: auto;
  margin-top: 4rem;
}

.ReclamationContent_Buttons__1ICxC {
  /* position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.ReclamationContent_QuestionTitle__25fIn {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.ReclamationContent_QuestionTitle__25fIn p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.ReclamationContent_RadioFormTwo__nJcRp {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.ReclamationContent_RadioElementTwo__3afZb {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.ReclamationContent_RadioElementTwo__3afZb input[type="radio"] {
  display: none;
}

.ReclamationContent_RadioElementTwo__3afZb input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.ReclamationContent_RadioElementTwo__3afZb input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.ReclamationContent_RadioElementTwo__3afZb input[type="radio"] + label:hover {
  cursor: pointer;
}

.ReclamationContent_RadioElementTwo__3afZb input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.ReclamationContent_RadioElementTwo__3afZb input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.ReclamationContent_RadioElementTwo__3afZb input[type="radio"]:checked + label i {
  display: inline-block;
}
.ReclamationContent_RadioElementTwo__3afZb input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ReclamationContent_TitleField__100Bi {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionField__2zbbT {
  margin-top: 1rem;
}

.ReclamationContent_ImagesRow__34kjA {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ReclamationContent_ImagePlaceholder__42_ON {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReclamationContent_ImageUploaded__34i5B {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ReclamationContent_RecapTags__3a4hP {
  display: flex;
}
.ReclamationContent_RecapTags__3a4hP p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.ReclamationContent_RecapTitle__3NFtD {
  margin-top: 1rem;
}
.ReclamationContent_TitleArea__1RZl2 {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_TitleArea__1RZl2::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapDescription__1uI5L {
  margin-top: 1rem;
}
.ReclamationContent_DescriptionArea__2ZBRc {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ReclamationContent_DescriptionArea__2ZBRc::-webkit-scrollbar {
  display: none;
}
.ReclamationContent_RecapImagesRow__1POee {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .ReclamationContent_RadioElementTwo__3afZb {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}

.Footer_Footer__36iyi {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Footer_LogoContainer__uZYei {
  width: 15em;
  margin: 1em 0;
}
.Footer_LogoContainer__uZYei img {
  width: 100%;
}
.Footer_LegalLinks__ZlG7g {
  display: flex;
  margin-top: 1em;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.5rem;
}
.Footer_LegalLinks__ZlG7g p {
  cursor: pointer;
  margin: 0.5rem;
  font-size: 0.8rem;
  color: #828282;
  padding: 0.8rem 1rem;
  background-color: #f3f2f0;
  border-radius: 10px;
}
.Footer_Copyrights__1fYfD {
  margin: 1em 0;
  color: #5b5855;
  text-align: center;
  font-size: 0.8rem;
}
.Footer_Copyrights__1fYfD span {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif;
}
.Footer_ImmobiliereLogo__XvWNu {
  width: 17rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.CardModal_CardModal__3-YFV {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal_CardModal__3-YFV {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal_CardModal__3-YFV {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}

.concertableLayoutOne_Card__3ESi7 {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.concertableLayoutOne_CardHeader__3boBV {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.concertableLayoutOne_CardTitle__20vRX h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.concertableLayoutOne_CardTitle__20vRX h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.concertableLayoutOne_ImagePreview__1nxL3 {
  margin: 0.5em auto;
  width: 80%;
  max-width: 20em;
}
.concertableLayoutOne_ImagePreview__1nxL3 img {
  width: 100%;
}

.concertableLayoutOne_Description__3rvpg {
  margin: 0.5rem 2.5rem;
  color: #898786;
  font-size: 0.9rem;
}

.concertableLayoutOne_CardContent__1hWTe {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.concertableLayoutOne_Emoji__1eIe4 {
  font-size: 1.8rem;
}

.concertableLayoutOne_QuestionsHeader__1VXQa {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.concertableLayoutOne_QuestionTitle__3Il0s {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.concertableLayoutOne_QuestionTitle__3Il0s p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.concertableLayoutOne_RadioForm__kxYSx {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.concertableLayoutOne_RadioElement__1WJop {
  margin: 1em 0.8em;
}

.concertableLayoutOne_RadioElement__1WJop label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.concertableLayoutOne_RadioElement__1WJop p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.concertableLayoutOne_RadioElement__1WJop img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5em;
  /* border-radius: 50%; */
  /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14); */
}

.concertableLayoutOne_RadioElement__1WJop input {
  display: none;
}

.concertableLayoutOne_RadioElement__1WJop input[type="radio"]:checked + label img {
  border: 3px solid #e31869;
}
.concertableLayoutOne_RadioElement__1WJop input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* TESTING ====================================================================== */
/* .RadioForm .RadioElementTwo {
  margin: 10px 5px 5px 0;
  display: inline-block;
} */

.concertableLayoutOne_RadioFormTwo__1l09Q {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
}

.concertableLayoutOne_RadioElementTwo__xoIb1 {
  margin: 1em 1em;
}

.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"] {
  display: none;
}

.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"] + label + p {
  text-align: center;
  width: 8em;
}

.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 8em;
  width: 8em;
  display: inline-block;
  position: relative;
  border: 4px solid rgb(196, 196, 196);
  border-radius: 5%;
}

.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"] + label:hover {
  cursor: pointer;
}

.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"]:checked + label i {
  display: inline-block;
}
.concertableLayoutOne_RadioElementTwo__xoIb1 input[type="radio"]:checked + label + p {
  font-weight: bold;
}

/* ============ END RADIO FORM */

.concertableLayoutOne_CommentSection__2wrd1 {
  margin-top: 1rem;
  text-align: center;
}

.concertableLayoutOne_Buttons__aPSVi {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.concertableLayoutOne_MUIForm__2X6C5 {
  margin-left: 2em;
}

.concertableLayoutOne_NCSection__1IRQR {
  margin-top: 1.1em;
}
.concertableLayoutOne_MapImage__EgCRn {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.concertableLayoutOne_Notices__3iIW9 {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .concertableLayoutOne_Card__3ESi7 {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    outline: none;
    display: flex;
  }
  .concertableLayoutOne_Card__3ESi7::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .concertableLayoutOne_Card__3ESi7 {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .concertableLayoutOne_LeftSection__1nBvi {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .concertableLayoutOne_RightSection__297k2 {
    background-color: #f3f2f0;
    width: 50%;

    position: relative;
  }

  .concertableLayoutOne_CardContent__1hWTe {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ConcertableSeven_ModalCreateReclamation__3FtOC {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ConcertableSeven_ModalCreateReclamation__3FtOC::-webkit-scrollbar {
  display: none;
}

.ConcertableSeven_ModalContent__1Lan- {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ConcertableSeven_CardHeader__218z_ {
  display: flex;
  top: 0;
  left: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ConcertableSeven_CardTitle__3Jigr {
  margin-top: -2rem;
}

.ConcertableSeven_CardTitle__3Jigr h4 {
  font-size: 1.3rem;
  color: #292f33;
  font-weight: normal;
}

.ConcertableSeven_Description__2vF5U {
  margin: 0rem 1rem;
  margin-top: 0rem;
  color: #898786;
  font-size: 0.9rem;
}

.ConcertableSeven_Question__20Asz {
  background-color: #4e96ae;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: white;
  font-size: 0.85rem;
  margin-top: 0rem;
}

.ConcertableSeven_Buttons__ZlxUM {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ConcertableSeven_ModalContent__1Lan- {
    position: relative;
    border-radius: 10px;
    width: 35rem;
    height: auto;
    /* height: 98%;
    max-height: 50rem; */
    outline: none;
  }
  .ConcertableSeven_SvgContainer__2_Au8 {
    width: 70%;
    margin: 0 auto;
  }
  .ConcertableSeven_Description__2vF5U {
    margin: 0rem 1rem;
    margin-top: 0rem;
    color: #898786;
    font-size: 0.9rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.svgStyles_svgSelector__2re1o {
  cursor: pointer;
}
.svgStyles_RadioFormTwo__3n29Q {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  width: 20rem;
  overflow-x: scroll;
}

.svgStyles_RadioElementTwo__5IXw6 {
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;

  /*flex: 1 0 32%; */
}

.svgStyles_RadioElementTwo__5IXw6 input[type="radio"] {
  display: none;
}

.svgStyles_RadioElementTwo__5IXw6 input[type="radio"] + label + p {
  text-align: center;
  font-size: 0.8rem;
  color: #898786;
  width: 5rem;
}

.svgStyles_RadioElementTwo__5IXw6 input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  border-radius: 5px;
}

.svgStyles_RadioElementTwo__5IXw6 input[type="radio"] + label:hover {
  cursor: pointer;
}

.svgStyles_RadioElementTwo__5IXw6 input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.svgStyles_RadioElementTwo__5IXw6 input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.svgStyles_RadioElementTwo__5IXw6 input[type="radio"]:checked + label i {
  display: inline-block;
}
.svgStyles_RadioElementTwo__5IXw6 input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.ConcertableSix_ModalCreateReclamation__Firw9 {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ConcertableSix_ModalCreateReclamation__Firw9::-webkit-scrollbar {
  display: none;
}

.ConcertableSix_ModalContent__2S5tI {
  width: 100%;
  height: 100vh;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ConcertableSix_CardHeader__rErCy {
  display: flex;
  top: 0;
  left: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.ConcertableSix_CardTitle__14zR0 {
  margin-top: -2rem;
}

.ConcertableSix_CardTitle__14zR0 h4 {
  font-size: 1.3rem;
  color: #292f33;
  font-weight: normal;
}

.ConcertableSix_Description__2Lrzn {
  margin: 2rem 1rem;
  margin-top: 1rem;
  color: #898786;
  font-size: 0.9rem;
}

.ConcertableSix_Question__19_Pk {
  background-color: #4e96ae;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: white;
  font-size: 0.85rem;
  margin-top: 0rem;
}

.ConcertableSix_Buttons__13Qsa {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ConcertableSix_ModalContent__2S5tI {
    position: relative;
    border-radius: 10px;
    width: 35rem;
    height: auto;
    /* height: 98%;
    max-height: 50rem; */
    outline: none;
  }
  .ConcertableSix_SvgContainer__3Ub-0 {
    width: 70%;
    margin: 0 auto;
  }
  .ConcertableSix_Description__2Lrzn {
    margin: 0rem 1rem;
    margin-top: 0rem;
    color: #898786;
    font-size: 0.9rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}

.NonConcertableLayoutOne_Card__3KBHD {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.NonConcertableLayoutOne_CardHeader___Yeas {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.NonConcertableLayoutOne_CardTitle__2k6vN h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.NonConcertableLayoutOne_CardTitle__2k6vN h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.NonConcertableLayoutOne_VideoPreview__2i8yy {
  /* margin: 0.5em auto;
  width: 80%;
  max-width: 20em; */
  margin: 1em auto;
  display: flex;
  justify-content: center;
}

.NonConcertableLayoutOne_Description__mD4MN {
  margin: 0.5rem 0;
  color: #898786;
  font-size: 0.9rem;
}

.NonConcertableLayoutOne_DescriptionPlus__iqAhD {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #898786;
  font-size: 0.9rem;
  margin-top: 1.5rem;
}
.NonConcertableLayoutOne_DescriptionPlus__iqAhD span {
  color: #3d9dc7;
  font-weight: 900;
}

.NonConcertableLayoutOne_CardContent__1Z1AM {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.NonConcertableLayoutOne_Emoji__9_oRJ {
  font-size: 1.8rem;
}

.NonConcertableLayoutOne_QuestionsHeader__2FMO9 {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.NonConcertableLayoutOne_QuestionTitle__d11GW {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.NonConcertableLayoutOne_QuestionTitle__d11GW p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.NonConcertableLayoutOne_CommentSection__GGsF- {
  margin-top: 2rem;
  text-align: center;
}

.NonConcertableLayoutOne_Buttons__S9F-j {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.NonConcertableLayoutOne_NCSection__32-Wi {
  margin-top: 1.1em;
}
.NonConcertableLayoutOne_MapImage__1-KvW {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.NonConcertableLayoutOne_Notices__h3EWZ {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .NonConcertableLayoutOne_Card__3KBHD {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    max-width: 50rem;
    outline: none;
    display: flex;
  }
  .NonConcertableLayoutOne_Card__3KBHD::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .NonConcertableLayoutOne_Card__3KBHD {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .NonConcertableLayoutOne_VideoPreview__2i8yy {
    margin-top: 3rem;
  }

  .NonConcertableLayoutOne_LeftSection__-SmIW {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .NonConcertableLayoutOne_RightSection__1zKkw {
    background-color: #f3f2f0;
    width: 50%;
    position: relative;
  }

  .NonConcertableLayoutOne_DescriptionPlus__iqAhD {
    margin-top: 0;
  }

  .NonConcertableLayoutOne_CardContent__1Z1AM {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ProfilePage_HeaderImageWrapper__1TY7T {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.ProfilePage_HeaderImageWrapper__1TY7T img {
  width: 80%;
}

.ProfilePage_ProfilePage__2REe8 h1 {
  margin: 1rem 2rem;
}
.ProfilePage_ProfilePage__2REe8 h3 {
  margin: 1rem 0 1rem 2rem;
}
.ProfilePage_SubmitButton__2AmNY {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.OvalButton_OvalButton__3Q1js {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton_OvalButton__3Q1js:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.Resultats_Main__2ISCS {
  padding: 0 1em;
  margin-bottom: 2em;
}

.Resultats_Title__1ML-F {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Resultats_Title__1ML-F span {
  font-size: 1.5rem;
  font-weight: bold;
}
.Resultats_StatsIllustration__2K2rN {
  margin-top: 2em;
}
.Resultats_StatsContent__4LmJT {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.Resultats_StatsContent__4LmJT h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.Resultats_StatsContent__4LmJT p {
  font-size: 2rem;
  font-weight: bold;
}
.Resultats_Illustration__1mR8u {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats_Resultats__3m2Yh {
    margin: 0 2em;
    margin-top: 1em;
  }
}
/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats_Resultats__3m2Yh {
    margin: 0 10em;
  }
}

