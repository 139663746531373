.Header {
  background-color: #3b4578;
  padding: 2rem 1.5rem;
}

.Toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Title {
  margin-top: 1.5rem;
  color: white;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.Title h3 {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 900;
}
.Title h4 {
  font-size: 1.6rem;
  font-weight: 600;
}

.Description {
  color: white;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Header {
    padding: 4rem 4rem;
  }
  .Title h3 {
    font-size: 1.8rem;
  }
  .Title {
    display: flex;
  }
  .Title h4 {
    margin-left: 1rem;
  }
  .Description {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Header {
    padding: 2rem 11rem;
  }

  .Title h3 {
    font-size: 2rem;
  }
}
