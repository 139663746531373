.ModalCreateReclamation {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalCreateReclamation::-webkit-scrollbar {
  display: none;
}

.ModalContent {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.CardHeader {
  display: flex;
  top: 0;
  left: 0;
  margin: 0;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.CardTitle {
  margin-top: -2rem;
}

.CardTitle h4 {
  font-size: 1.3rem;
  color: #292f33;
  font-weight: normal;
}

.Description {
  margin: 0rem 1rem;
  margin-top: 0rem;
  color: #898786;
  font-size: 0.9rem;
}

.Question {
  background-color: #4e96ae;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: white;
  font-size: 0.85rem;
  margin-top: 0rem;
}

.Buttons {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalContent {
    position: relative;
    border-radius: 10px;
    width: 35rem;
    height: auto;
    /* height: 98%;
    max-height: 50rem; */
    outline: none;
  }
  .SvgContainer {
    width: 70%;
    margin: 0 auto;
  }
  .Description {
    margin: 0rem 1rem;
    margin-top: 0rem;
    color: #898786;
    font-size: 0.9rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
