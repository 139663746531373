.svgSelector {
  cursor: pointer;
}
.RadioFormTwo {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  width: 20rem;
  overflow-x: scroll;
}

.RadioElementTwo {
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;

  /*flex: 1 0 32%; */
}

.RadioElementTwo input[type="radio"] {
  display: none;
}

.RadioElementTwo input[type="radio"] + label + p {
  text-align: center;
  font-size: 0.8rem;
  color: #898786;
  width: 5rem;
}

.RadioElementTwo input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  border-radius: 5px;
}

.RadioElementTwo input[type="radio"] + label:hover {
  cursor: pointer;
}

.RadioElementTwo input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.RadioElementTwo input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.RadioElementTwo input[type="radio"]:checked + label i {
  display: inline-block;
}
.RadioElementTwo input[type="radio"]:checked + label + p {
  font-weight: bold;
}
