.IntroImage {
  margin: auto;
  margin-top: 4rem;
}

.Buttons {
  /* position: absolute;
  bottom: 2rem;
  right: 2rem;
  left: 2rem; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.QuestionTitle {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.QuestionTitle p {
  color: #2a2f39;
  margin-left: 0.4em;
}

.RadioFormTwo {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
}

.RadioElementTwo {
  margin-top: 1rem;
  /*flex: 1 0 32%; */
}

.RadioElementTwo input[type="radio"] {
  display: none;
}

.RadioElementTwo input[type="radio"] + label + p {
  text-align: left;
  font-size: 0.8rem;
  width: 5rem;
}

.RadioElementTwo input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 5rem;
  width: 5rem;
  display: inline-block;
  position: relative;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.RadioElementTwo input[type="radio"] + label:hover {
  cursor: pointer;
}

.RadioElementTwo input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.RadioElementTwo input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.RadioElementTwo input[type="radio"]:checked + label i {
  display: inline-block;
}
.RadioElementTwo input[type="radio"]:checked + label + p {
  font-weight: bold;
}

.TitleField {
  margin-top: 1rem;
}
.DescriptionField {
  margin-top: 1rem;
}

.ImagesRow {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.ImagePlaceholder {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ImageUploaded {
  border-radius: 5px;
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.RecapTags {
  display: flex;
}
.RecapTags p {
  padding: 0.5rem;
  background-color: #ee9834;
  border-radius: 16px;
  margin-right: 1rem;
  color: white;
  font-size: 0.8rem;
}
.RecapTitle {
  margin-top: 1rem;
}
.TitleArea {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 2.5rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.TitleArea::-webkit-scrollbar {
  display: none;
}
.RecapDescription {
  margin-top: 1rem;
}
.DescriptionArea {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  height: 7rem;
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.DescriptionArea::-webkit-scrollbar {
  display: none;
}
.RecapImagesRow {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

/* large tablets & laptops */
@media screen and (min-width: 401px) {
  .RadioElementTwo {
    margin-top: 1rem;
    padding-left: 2rem;
    flex: 1 0 32%;
  }
}
