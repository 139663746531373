.Projet {
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
}
.Projet p {
  margin-bottom: 1.5rem;
  color: #5b5855;
}
.Articles {
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}
.ArticlesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Description p {
  text-align: justify;
}

.ActionButton {
  display: none;
}
.ActionButton p {
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
  color: white;
  background: #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  margin-top: 4rem;
}
.ActionButtonSvg {
  display: none;
}

.Description ul {
  list-style: none;
  margin-left: 2rem;
  color: #5b5855;
}

.Description ul li::before {
  content: "\2022";
  color: #8bc63a;
  font-weight: bold;
  display: inline-block;
  width: 1.5em;
  margin-left: -1em;
}
.DescriptionSvg {
  max-width: 20rem;
  margin: 0 auto;
}
.LetterTitle {
}
.LetterPhoto {
  width: 40%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Projet {
    display: flex;
    flex-flow: row;
    padding: 4rem 4rem;
  }
  .Articles {
    margin-top: 0;
  }
  .Description {
    margin-right: 5rem;
  }
  .DescriptionSvg {
    display: none;
  }
  .DescriptionSection {
    position: relative;
  }
  .Description {
    /* margin-left: 13vw; */
    margin-right: 5vw;
  }

  .ActionButton {
    display: none;
  }

  .BackgroundShape {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
  .LetterTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .LetterPhoto {
    width: 10rem;
    margin: 0;
    margin-left: 1rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Projet {
    padding: 4rem 11rem;
    padding-right: 5rem;
  }

  .DescriptionSection {
    position: relative;
  }
  .Description {
    margin-left: 13vw;
    margin-right: 5vw;
  }
  .DescriptionSvg {
    display: block;
    width: 25vw;
    position: absolute;
    left: -11rem;
    top: -3rem;
    z-index: -1;
  }

  .ActionButton {
    display: block;
    position: relative;
  }

  .ActionButtonSvg {
    display: block;
    width: 15rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }
  .ActionButtonSvg::before {
    content: "";
    transform: translate(-50%, 50%);
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .BackgroundShape {
    position: absolute;
    width: 37vw;
    height: 37vw;
    border-radius: 65% 37% 35% 64% / 58% 55% 37% 41%;
    background-color: #e3e7f1;
    /* background-color: lightcoral; */
    z-index: -2;
    right: -4rem;
    top: -4rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
