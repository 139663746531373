.Header {
  background-color: #f3f2f0;
  padding: 2rem 1.5rem;
}

.Toolbar {
  display: flex;
  align-items: left;
  flex-direction: column;
}
.Logo {
  width: 90vw;
  margin-top: 1rem;
}

.Title {
  margin-top: 1.5rem;
  font-family: "Nunito Sans";
  line-height: 1.7rem;
}
.Title h3 {
  font-size: 1.8rem;
  color: #292f33;
  text-transform: uppercase;
  font-weight: 900;
}
.Title h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #5b5855;
}

.Description {
  color: #5b5855;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 1.125rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Header {
    padding: 2rem 4rem;
  }
  .Toolbar {
    align-items: center;
    flex-direction: row;
  }
  .Logo {
    width: 30vw;
    margin: 0;
  }
  .Title h3 {
    font-size: 1.8rem;
  }
  .Title {
    display: flex;
    flex-direction: column;
  }
  .Title h4 {
    margin-left: 0;
  }
  .Description {
    width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Header {
    padding: 2rem 11rem;
  }
  .Logo {
    margin-left: 2rem;
  }

  .Title h3 {
    font-size: 2rem;
  }
}
