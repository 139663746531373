.Modal {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  width: 90%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.Modal::-webkit-scrollbar {
  display: none;
}

.ModalContent {
  width: 90%;
  outline: none;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalContent {
    width: 50%;
  }
  .Modal {
    width: 50%;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .ModalContent {
    width: 40%;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
